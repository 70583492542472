import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import { Spin } from 'antd';
import Image from '../components/image';
import SEO from '../components/seo';
const IndexPage = () => {
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (localStorage.getItem('token') === null) {
      navigate('/auth');
    } else {
      if (data.role_id > 50 && data.role_id < 100) {
        navigate('/dashboard');
      } else if (data.role_id > 40 && data.role_id < 50) {
        navigate('/yard/home');
      } else if (data.role_id === 999) {
        navigate('/client');
      }
      else if (data.role_id === 199) {
        navigate('/equipment-drop-off');
      }
    }
  }, []);

  return (
    <div className="spinner-main">
      <Spin />
    </div>
  );
};
{
  /* <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */
}

export default IndexPage;
